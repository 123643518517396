<template>
  <UBanner
    v-if="!loading"
  >
    <article
      class="box"
    >
      <h1 class="page-title">
        {{ localDocumentTitle }}
      </h1>

      <SessionsFilter
        v-model="sessions"
        @change="value => filteredSessions = value"
      />

      <div v-if="sessions.length">
        <VRow v-if="filteredSessions.length">
          <VCol
            v-for="session in filteredSessions"
            :key="session.order_id"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            xl="3"
          >
            <SessionCard
              :to="getTo(session)"
              :session="session"
              refreshable
              :is-show-documents="shouldShowDocuments(session)"
            />
          </VCol>
        </VRow>

        <p v-else>
          По данным параметрам не удалось найти курсы.
        </p>
      </div>

      <UPlaceholder v-else />
    </article>
  </UBanner>
  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SessionsFilter from '@components/SessionsFilter.vue'
import SessionCard from '@components/SessionCard.vue'
import documentTitle from '@/mixins/documentTitle'
import UPlaceholder from '@components/UPlaceholder.vue'
import UBanner from '@components/UBanner.vue'
import * as getters from '@/store/getters/types'
import * as actions from '@/store/actions/types'

export default {
  name: 'TheSessions',

  components: {
    UPlaceholder,
    SessionsFilter,
    SessionCard,
    UBanner,
  },

  mixins: [
    documentTitle
  ],

  data () {
    return {
      documentTitle: '$sdo.lc_menu_sessions.other',
      filteredSessions: [],
      loading: true
    }
  },

  computed: {
    ...mapGetters({
      sessions: getters.SESSIONS
    })
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      getSessions: actions.GET_SESSIONS
    }),

    getTo (session) {
      if (session.is_started && (session.is_active || session.order_status.code === 'training_complete')) {
        return { name: 'session', params: { sessionID: session.id } }
      }

      return ''
    },

    shouldShowDocuments (session) {
      return (session.is_active || session.order_status.code === 'training_complete') &&
             session.is_started &&
             (!!session.documents_count || !!session.textbooks_count)
    },

    async register () {
      try {
        await this.getSessions()
        this.filteredSessions = this.sessions
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
